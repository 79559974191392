// frontend/src/components/ui_components/Container/Container.jsx
import React, { useContext } from 'react';
import { NestedComponentsContext } from '../../../contexts/NestedComponentsContext';
import { TokensContext } from '../../../contexts/TokensContext';
import { ComponentPropsContext } from '../../../contexts/ComponentPropsContext';
import ContainerControlBar from '../ContainerControlBar';
import { getComponentProps } from '../../../hooks/getComponentProps';

const Container = ({ id, children, handleComponentClick, tokens, ...props }) => {
  const { nestedComponents } = useContext(NestedComponentsContext);
  const { designTokens, editedTokens } = useContext(TokensContext);
  const { selectedComponentProps } = useContext(ComponentPropsContext);

  const nestedComponentsForContainer = nestedComponents[id] || {};

  // Default tokens
  const defaultTokens = {
    flexGrow: '1',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  };

  const mergedTokens = {
    ...defaultTokens,
    ...tokens,
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const renderNestedComponents = () => {
    return Object.keys(nestedComponentsForContainer).map((columnId) => {
      const columnData = nestedComponentsForContainer[columnId];
      return Array.isArray(columnData)
        ? columnData.map((component) => {
            const Component = component.component;

            // Compute tokens for nested component
            const nestedTokens = {
              ...designTokens[component.id],
              ...component.tokens,
              ...editedTokens[component.id],
            };

            const componentProps = selectedComponentProps[component.id] || getComponentProps(Component);

            return (
              <div
                key={component.id}
                style={{
                  width: '100%',
                  height: 'auto',
                  flex: '1 1 auto',
                  margin: '10px',
                  zIndex: 10,
                  position: 'relative',
                  cursor: 'pointer',
                  pointerEvents: 'all',
                }}
                className="nested-component"
                onClick={(e) => {
                  e.stopPropagation();
                  handleComponentClick(component);
                }}
              >
                <Component
                  {...componentProps}
                  id={component.id}
                  tokens={nestedTokens}
                />
              </div>
            );
          })
        : null;
    });
  };

  return (
    <div className="ContainerComponentWrapper" style={{ width: '100%', position: 'relative' }}>
      {/* Control bar for container settings */}
      <ContainerControlBar containerId={id} handleComponentClick={handleComponentClick} />

      <div
        style={{
          ...mergedTokens,
          ...props.style,
          zIndex: 1,
        }}
        className="container-component"
        onDrop={(event) => event.preventDefault()}
        onDragOver={allowDrop}
      >
        {children}
        {renderNestedComponents()}
      </div>
    </div>
  );
};

export default Container;
