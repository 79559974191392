// frontend/src/components/ui_components/AddNestedComponent.jsx
import React, { useState, useContext } from 'react';
import { NestedComponentsContext } from '../../contexts/NestedComponentsContext';
import { v4 as uuidv4 } from 'uuid';
import { components as componentImports } from '../ComponentImports';
import { PlusOutlined } from '@ant-design/icons';

const AddNestedComponent = ({ containerId }) => {
  const [selectedComponent, setSelectedComponent] = useState('');
  const { addNestedComponent } = useContext(NestedComponentsContext);

  const handleAddComponent = async () => {
    const ComponentModule = await componentImports[selectedComponent]();
    const Component = ComponentModule.default;

    const newComponent = {
      id: uuidv4(),
      component: Component,
      name: selectedComponent,
      props: {},
    };

    addNestedComponent(containerId, 0, newComponent);
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <button
        onClick={handleAddComponent}
        style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}
      >
        <PlusOutlined />
      </button>
      <select
        value={selectedComponent}
        onChange={(e) => setSelectedComponent(e.target.value)}
        style={{ marginLeft: '5px' }}
      >
        <option value="">Select Component</option>
        {Object.keys(componentImports).map((componentName) => (
          <option key={componentName} value={componentName}>
            {componentName}
          </option>
        ))}
      </select>
    </div>
  );
};

export default AddNestedComponent;
