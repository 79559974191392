// frontend/src/contexts/TokensContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const TokensContext = createContext();

export const TokensProvider = ({ children }) => {
  const [globalTokens, setGlobalTokens] = useState({});
  const [designTokens, setDesignTokens] = useState({});
  const [editedTokens, setEditedTokens] = useState({});
  const [forceRender, setForceRender] = useState(false);

  const updateDesignTokens = (componentId, newTokens) => {
    setEditedTokens((prevTokens) => {
      const updatedTokens = {
        ...prevTokens,
        [componentId]: {
          ...(prevTokens[componentId] || {}),
          ...newTokens,
        },
      };
      console.log('TokensContext: updateDesignTokens called, new editedTokens:', updatedTokens);
      return updatedTokens;
    });
  };

  // Add useEffect to listen for token changes and trigger re-render
  useEffect(() => {
    if (Object.keys(editedTokens).length > 0) {
      setForceRender((prev) => !prev); // This should toggle and force a re-render
    }
  }, [editedTokens]);

  return (
    <TokensContext.Provider
      value={{
        globalTokens,
        designTokens,
        editedTokens,
        setGlobalTokens: (tokens) => {
          console.log('TokensContext: setGlobalTokens called with:', tokens);
          setGlobalTokens(tokens);
        },
        setDesignTokens: (tokens) => {
          console.log('TokensContext: setDesignTokens called with:', tokens);
          setDesignTokens(tokens);
        },
        setEditedTokens: (tokens) => {
          console.log('TokensContext: setEditedTokens called with:', tokens);
          setEditedTokens(tokens);
        },
        updateDesignTokens,
      }}
    >
      {children}
    </TokensContext.Provider>
  );
};