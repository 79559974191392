// frontend/src/components/homepage/HomeSection6/HomeSection6.js
import React, { useEffect, useRef } from 'react';
import './HomeSection6.css';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import ImageLeft from '../../../assets/homepage/HomeSection6/jira-panel.png';
import ImageRight from '../../../assets/homepage/HomeSection6/jira-ticket.png';

const HomeSection6 = () => {
  const headingRef = useRef(null);
  const leftImageRef = useRef(null);
  const rightImageRef = useRef(null);
  const rightTextRef = useRef(null);
  useEffect(() => {
    const options = {
      threshold: 0.1, // Trigger when 10% of the element is visible
    };
    const handleIntersection = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in-up');
          if (entry.target === leftImageRef.current) {
            setTimeout(() => {
              rightImageRef.current.classList.add('slide-in-up');
            }, 500);
            setTimeout(() => {
              rightTextRef.current.classList.add('fade-in');
            }, 1000);
          }
        }
      });
    };
    const observer = new IntersectionObserver(handleIntersection, options);
    if (headingRef.current) observer.observe(headingRef.current);
    if (leftImageRef.current) observer.observe(leftImageRef.current);
    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (leftImageRef.current) observer.unobserve(leftImageRef.current);
    };
  }, []);
  
  return (
    <div className="home-section-6">
      <div ref={headingRef} className="homeHeadingH2">
        <img src={HeadingKeyline} alt="Heading Keyline" className="HeadingKeyline" />
        <h2>Work smarter</h2>
        <p>Edit and track Jira tickets without leaving the app.</p>
      </div>

      <div className="content-wrapper">
        <div ref={leftImageRef} className="left-column">
          <img src={ImageLeft} alt="Jira Backlog View" />
        </div>
        <div className="right-column">
          <img ref={rightImageRef} src={ImageRight} alt="Jira Ticket Detail View" />
          <p ref={rightTextRef}>
            Access and manage your tagged Jira tickets directly from your project board within the app, eliminating distractions and boosting productivity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomeSection6;
