// frontend/src/components/properties-sidebar/FlexOptions.js

import React from 'react';
import { Row, Col, Button, Dropdown, Menu, Input } from 'antd';
import {
  JustifyContentFlexStart,
  JustifyContentFlexEnd,
  AlignItemsFlexStart,
  AlignItemsFlexEnd,
  JustifyContentCenter,
  AlignItemsCenter,
  MoreFlexOptions,
  FlexWrapNowrap,
  FlexWrapWrap,
  FlexDirectionRowReverse,
  FlexDirectionRow,
  FlexDirectionColumn,
  FlexDirectionColumnReverse,
} from '../../assets/icons/properties-sidebar/icons';
import { getRelevantTokens } from './utils';
import { DownOutlined } from '@ant-design/icons';

const FlexOptions = ({
  handleTokenChange,
  globalTokens,
  editedTokens,
  setEditedTokens,
  selectedComponentInfo,
  tokenNames,
  setTokenNames,
}) => {
  if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

  const currentTokens = editedTokens[selectedComponentInfo.id] || {};

  const renderButton = (propertyType, value, icon) => {
    const isActive = currentTokens[propertyType] === value;
    return (
      <Button
        className={`flex-button ${isActive ? 'active' : ''}`}
        icon={icon}
        onClick={() => handleTokenChange(selectedComponentInfo.id, propertyType, value)}
      />
    );
  };

  const renderDropdown = (propertyType) => {
    const relevantTokens = getRelevantTokens(globalTokens, propertyType);

    const menu = (
      <Menu
        onClick={(e) => {
          handleTokenChange(selectedComponentInfo.id, propertyType, relevantTokens[e.key]);
          setEditedTokens((prevTokens) => ({
            ...prevTokens,
            [selectedComponentInfo.id]: {
              ...prevTokens[selectedComponentInfo.id],
              [propertyType]: relevantTokens[e.key],
            },
          }));
          // Update tokenNames to store the selected token name
          setTokenNames((prevTokenNames) => ({
            ...prevTokenNames,
            [selectedComponentInfo.id]: {
              ...prevTokenNames[selectedComponentInfo.id],
              [propertyType]: e.key,
            },
          }));
        }}
      >
        {Object.entries(relevantTokens).map(([key, value]) => (
          <Menu.Item key={key}>
            {key} - {value}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <Button style={{ cursor: 'pointer' }}>
          <MoreFlexOptions />
        </Button>
      </Dropdown>
    );
  };

  const renderGapInput = (label, propertyType) => {
    const tokenName = tokenNames[selectedComponentInfo.id]?.[propertyType] || '';
    const inputValue = currentTokens[propertyType] || '';

    const relevantTokens = getRelevantTokens(globalTokens, 'sizeScale');

    const menu = (
      <Menu
        onClick={(e) => {
          handleTokenChange(selectedComponentInfo.id, propertyType, relevantTokens[e.key]);
          setEditedTokens((prevTokens) => ({
            ...prevTokens,
            [selectedComponentInfo.id]: {
              ...prevTokens[selectedComponentInfo.id],
              [propertyType]: relevantTokens[e.key],
            },
          }));
          setTokenNames((prevTokenNames) => ({
            ...prevTokenNames,
            [selectedComponentInfo.id]: {
              ...prevTokenNames[selectedComponentInfo.id],
              [propertyType]: e.key,
            },
          }));
        }}
      >
        {Object.entries(relevantTokens).map(([key, value]) => (
          <Menu.Item key={key}>
            {key} - {value}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <div
        className="prop-sidebar-item"
        style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
      >
        <label alt={label}></label>
        <Input
          type="text"
          value={tokenName || inputValue}
          placeholder={propertyType}
          onChange={(e) => handleTokenChange(selectedComponentInfo.id, propertyType, e.target.value)}
        />
        <Dropdown overlay={menu} trigger={['click']}>
          <Button style={{ cursor: 'pointer' }}>
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    );
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={2}>{renderButton('justifyContent', 'flex-start', <JustifyContentFlexStart />)}</Col>
        <Col span={2}>{renderButton('justifyContent', 'flex-end', <JustifyContentFlexEnd />)}</Col>
        <Col span={2}>{renderButton('alignItems', 'flex-start', <AlignItemsFlexStart />)}</Col>
        <Col span={2}>{renderButton('alignItems', 'flex-end', <AlignItemsFlexEnd />)}</Col>
        <Col span={2}>{renderButton('justifyContent', 'center', <JustifyContentCenter />)}</Col>
        <Col span={2}>{renderButton('alignItems', 'center', <AlignItemsCenter />)}</Col>
        <Col span={2}>{renderDropdown('justifyContent')}</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={2}>{renderButton('flexWrap', 'nowrap', <FlexWrapNowrap />)}</Col>
        <Col span={2}>{renderButton('flexWrap', 'wrap', <FlexWrapWrap />)}</Col>
        <Col span={2}>{renderButton('flexDirection', 'row-reverse', <FlexDirectionRowReverse />)}</Col>
        <Col span={2}>{renderButton('flexDirection', 'row', <FlexDirectionRow />)}</Col>
        <Col span={2}>{renderButton('flexDirection', 'column', <FlexDirectionColumn />)}</Col>
        <Col span={2}>{renderButton('flexDirection', 'column-reverse', <FlexDirectionColumnReverse />)}</Col>
        <Col span={2}>{renderDropdown('flexWrap')}</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={12}>
          {renderGapInput('Column Gap', 'columnGap')}
        </Col>
        <Col span={12}>
          {renderGapInput('Row Gap', 'rowGap')}
        </Col>
      </Row>
    </>
  );
};

export default FlexOptions;
