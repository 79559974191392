// frontend/src/components/homepage/HomeSection3/HomeSection3.js
import React, { useEffect, useRef } from 'react';
import './HomeSection3.css';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import VerticalScrollIndicator from '../../general/VerticalScrollIndicator/VerticalScrollIndicator';
import VideoSource from '../../../assets/homepage/HomeSection3/drag-drop-example.mp4';

const HomeSection3 = () => {
  const headingRef = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 0.1, // Trigger when 10% of the element is visible
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === headingRef.current) {
            entry.target.classList.add('slide-in-up');
          }
          if (entry.target === videoRef.current) {
            entry.target.classList.add('slide-in-up-video');
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (headingRef.current) observer.observe(headingRef.current);
    if (videoRef.current) observer.observe(videoRef.current);

    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (videoRef.current) observer.unobserve(videoRef.current);
    };
  }, []);

  return (
    <div className="home-section-3">
      <div ref={headingRef} className="homeHeadingH2">
        <img src={HeadingKeyline} alt="HeadingKeyline" className="HeadingKeyline" />
        <h2>Drag, drop, done</h2>
        <p>Build websites with our visual editor, no code required.</p>
      </div>
      <div className="video-container">
        <VerticalScrollIndicator />
        <video 
          ref={videoRef}
          src={VideoSource} 
          autoPlay 
          loop 
          muted 
          playsInline 
          className="demo-video"
        />
      </div>
    </div>
  );
};

export default HomeSection3;
