// fontend/src/comopnents/ComponentSidebar.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { fetchUserComponents } from './UserComponentImports';
import { components } from './ComponentImports';
import { loadComponentFromS3, registerDynamicComponent } from './DynamicComponentLoader';
import ComponentWindow from './ComponentWindow';
import { CaretRightOutlined } from '@ant-design/icons';

const ComponentSidebar = ({ onDragStart, token, designTokens }) => {
  const [userComponents, setUserComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [imageUrls, setImageUrls] = useState({});
  const [draggedComponent, setDraggedComponent] = useState(null);
  const dragImageRef = useRef(null);
  const hiddenDragImageRef = useRef(null);
  const [testComponents, setTestComponents] = useState([]);

  useEffect(() => {
    async function loadUserComponents() {
      try {
        const components = await fetchUserComponents(token);
        console.log('Fetched user components:', components);
        setUserComponents(components);
      } catch (error) {
        console.error('Error loading user components:', error);
      }
    }

    loadUserComponents();
  }, [token]);

  useEffect(() => {
    const fetchTestComponents = async () => {
      try {
        const response = await axios.get('/component-management/list', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('Fetched test components from S3:', response.data.components);
        setTestComponents(response.data.components);
      } catch (error) {
        console.error('Error fetching components from S3:', error);
      }
    };

    fetchTestComponents();
  }, [token]);
  

  useEffect(() => {
    const fetchImages = async () => {
      const urls = {};
      for (const componentName of Object.keys(components)) {
        try {
          const response = await fetch(`/api/get-screenshot-url?componentName=${componentName}`);
          const { url } = await response.json();
          urls[componentName] = url;
          console.log(`Fetched image URL for "${componentName}": ${url}`);
        } catch (error) {
          console.error(`Error fetching image for "${componentName}":`, error);
        }
      }
      setImageUrls(urls);
      console.log('All image URLs fetched:', urls);
    };

    fetchImages();
  }, []);

  const handleDragStart = async (event, componentName, source = 'local') => {
    console.log(`Starting drag for component: "${componentName}", Source: "${source}"`);
    setDraggedComponent(componentName);

    // Prepare drag data
    const componentDesignTokens = designTokens[componentName] || {};
    const dragData = {
      componentSource: source,
      componentName: componentName,
      designTokens: componentDesignTokens,
    };

    // Set drag data using 'text/plain'
    event.dataTransfer.setData('text/plain', JSON.stringify(dragData));
    console.log(`Set drag data for component "${componentName}":`, dragData);

    // Asynchronously load the component if it's from S3
    if (source === 's3') {
      console.log(`Loading component "${componentName}" from S3`);
      loadComponentFromS3(componentName, token).then((Component) => {
        console.log(`Component loaded from S3:`, Component);
        if (Component) {
          registerDynamicComponent(components, componentName, Component, designTokens);
          console.log(`Component "${componentName}" registered dynamically.`);
        } else {
          console.error(`Component "${componentName}" could not be loaded from S3.`);
        }
      });
    }

    event.dataTransfer.setData('componentSource', source);
    event.dataTransfer.setData('componentName', componentName);
    event.dataTransfer.setData('designTokens', JSON.stringify(designTokens[componentName]));
    console.log(`Set drag data for component "${componentName}":`, {
      componentSource: source,
      designTokens: componentDesignTokens,
    });

    if (hiddenDragImageRef.current) {
      const img = new Image();
      img.src = imageUrls[componentName];
      img.onload = () => {
        const hiddenDiv = hiddenDragImageRef.current;
        hiddenDiv.innerHTML = ''; // Clear the hidden div
        hiddenDiv.appendChild(img); // Append the image to the hidden div

        // Set the drag image
        event.dataTransfer.setDragImage(hiddenDiv, img.width / 2, img.height / 2);
        console.log(`Drag image set for component "${componentName}"`);
      };
      img.onerror = (err) => {
        console.error(`Error loading image for drag image of "${componentName}":`, err);
      };
    }
  };

  const handleDragEnd = () => {
    console.log(`Ending drag for component: "${draggedComponent}"`);
    setDraggedComponent(null);
  };

  const handleOpenWindow = (componentName) => {
    console.log(`Opening component window for "${componentName}"`);
    setSelectedComponent({ componentName, designTokens: designTokens[componentName] });
  };

  return (
    <div className="left-sidebar">
      <h4>Components</h4>
      <ul>
        {Object.keys(components).map((componentName) => (
          <li
            key={componentName}
            draggable
            onDragStart={(e) => handleDragStart(e, componentName, 'local')}
            onDragEnd={handleDragEnd}
            className={draggedComponent === componentName ? 'dragging' : ''}
          >
            <div className="component-left-content">
              <div className="componentThumbnail">
                {imageUrls[componentName] ? (
                  <img src={imageUrls[componentName]} alt={componentName} />
                ) : (
                  <span className="mini-loading-label">Loading...</span>
                )}
              </div>
              <p>{componentName}</p>
            </div>
            <button onClick={() => handleOpenWindow(componentName)}><CaretRightOutlined /></button>
          </li>
        ))}
      </ul>
      <h4>Test Components</h4>
      <ul>
        {testComponents.map((component) => (
          <li
            key={component.key}
            draggable
            onDragStart={(e) => handleDragStart(e, component.key, 's3')}
            onDragEnd={handleDragEnd}
            className={draggedComponent === component.key ? 'dragging' : ''}
          >
            <div className="component-left-content">
              {/* Placeholder or actual image */}
              <p>{component.key}</p>
            </div>
            <button>
              <CaretRightOutlined />
            </button>
          </li>
        ))}
      </ul>
      <h4>User Components</h4>
      <ul>
        {userComponents.map(component => (
          <li
            key={component.name}
            draggable
            onDragStart={(e) => onDragStart(e, component.name)}
            onDragEnd={handleDragEnd}
            className={draggedComponent === component.key ? 'dragging' : ''}
          >
            <div className="component-left-content">
              {/* Placeholder or actual image */}
              <p>{component.name}</p>
            </div>
            <button onClick={() => {/* open component */}}><CaretRightOutlined /></button>
          </li>
        ))}
      </ul>
      {selectedComponent && (
        <ComponentWindow
          componentName={selectedComponent.componentName}
          designTokens={selectedComponent.designTokens}
        />
      )}
      <div ref={hiddenDragImageRef} style={{ position: 'absolute', top: '-1000px', left: '-1000px', visibility: 'hidden' }} />
    </div>
  );
};

export default ComponentSidebar;
