// frontend/src/components/homepage/HomeSection4/HomeSection4.js
import React, { useEffect, useRef } from 'react';
import './HomeSection4.css';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import Image1 from '../../../assets/homepage/HomeSection4/visualeditor.png';
import Image2 from '../../../assets/homepage/HomeSection4/codeeditor.png';

const HomeSection4 = () => {
  const headingRef = useRef(null);
  const image1Ref = useRef(null);
  const image2Ref = useRef(null);
  const textContainerRef = useRef(null);

  useEffect(() => {
    const options = {
      threshold: 0.1, // Trigger when 10% of the element is visible
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === headingRef.current) {
            entry.target.classList.add('slide-in-up');
          }
          if (entry.target === image1Ref.current) {
            entry.target.classList.add('slide-in-up');
          }
          if (entry.target === image2Ref.current) {
            entry.target.classList.add('slide-in-up-delay');
          }
          if (entry.target === textContainerRef.current) {
            entry.target.classList.add('slide-in-up-delay2');
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (headingRef.current) observer.observe(headingRef.current);
    if (image1Ref.current) observer.observe(image1Ref.current);
    if (image2Ref.current) observer.observe(image2Ref.current);
    if (textContainerRef.current) observer.observe(textContainerRef.current);

    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (image1Ref.current) observer.unobserve(image1Ref.current);
      if (image2Ref.current) observer.unobserve(image2Ref.current);
      if (textContainerRef.current) observer.unobserve(textContainerRef.current);
    };
  }, []);
  
  return (
    <div className="home-section-4">
      <div ref={headingRef} className="homeHeadingH2">
        <img src={HeadingKeyline} alt="HeadingKeyline" className="HeadingKeyline" />
        <h2>Unleash your creativity</h2>
        <p>Combine visual and code editors to build websites your way.</p>
      </div>
      <div className="content-container">
        <div className="images-container">
          <img ref={image1Ref} src={Image1} alt="Image 1" className="image image1" />
          <div className="image2withText">
            <img ref={image2Ref} src={Image2} alt="Image 2" className="image image2" />
            <div ref={textContainerRef} className="text-container">
              <p>
                Effortlessly switch between code view and visual editor to preview and make changes instantly!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSection4;
