// frontend/src/components/properties-sidebar/ColorOptions.js

import React from 'react';
import { Row, Col } from 'antd';

const ColorOptions = ({
  globalTokens,
  editedTokens,
  selectedComponentInfo,
  handleTokenChange,
  tokenNames,
  renderColorPicker,
  setEditedTokens,
  setTokenNames,
}) => {
  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {renderColorPicker(
            'backgroundColor',
            selectedComponentInfo,
            globalTokens,
            editedTokens,
            handleTokenChange,
            tokenNames,
            setEditedTokens,
            setTokenNames
          )}
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={24}>
          {renderColorPicker(
            'color',
            selectedComponentInfo,
            globalTokens,
            editedTokens,
            handleTokenChange,
            tokenNames,
            setEditedTokens,
            setTokenNames
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ColorOptions;
