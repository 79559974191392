import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './header/Header.css';
import { Modal, Tabs } from 'antd';
//import Register from './Register';
import Login from './Login';
import Logo from '../assets/webbify-logo.svg';
import LogoWhite from '../assets/webbify-logo2.svg';

const { TabPane } = Tabs;

const Header = ({ isLoggedIn, setIsLoggedIn }) => {
  const [visible, setVisible] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();

  const showModal = () => {
    if (!isLoggedIn) {
      setVisible(true);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    navigate('/');
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    navigate('/');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) { // Adjust this value to control when the class is added
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={scrolled ? 'scrolled' : ''}>
      <div className="logo">
        <Link to="/" className="logoLink">
          <img className="defaultLogo" src={Logo} alt="Webbify Logo" />
          <img className="whiteLogo" src={LogoWhite} alt="Webbify Logo" />
        </Link>
      </div>
      <ul className={`header-links ${menuOpen ? 'open' : ''}`}>
        {/* Ensure all elements are wrapped correctly */}
        {isLoggedIn && (
          <>
            <li><Link to="/uibuilder">Visual Editor</Link></li>
            <li><Link to="/CodeEditor">Code Editor</Link></li>
            <li><Link to="/media">Media</Link></li>
            <li><Link to="#">Analytics & Testing</Link></li>
          </>
        )}
      </ul>
      <div className="header-right-actions">
        {isLoggedIn && (
          <>
            <li><Link to="/dashboard">Settings</Link></li>
          </>
        )}
        {isLoggedIn ? (
          <button onClick={handleLogout}>Logout</button>
        ) : (
          <button onClick={showModal}>Log in</button>
        )}
        <Modal
          title="Log in"
          visible={visible}
          onCancel={handleCancel}
          footer={null}
        >
          <Tabs defaultActiveKey="login">
            <TabPane tab="Login" key="login">
              <Login setIsLoggedIn={handleLogin} />
            </TabPane>
            {/*<TabPane tab="Register" key="register">
              <Register />
            </TabPane> */}
          </Tabs>
        </Modal>
      </div>

      {/* Hamburger Icon */}
      <div className="hamburger" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {/* Fullscreen Menu */}
      <div className={`fullscreen-menu ${menuOpen ? 'show' : ''}`}>
        <div className="close-icon" onClick={toggleMenu}>
          &times;
        </div>
        <ul>
          {isLoggedIn && (
            <>
              <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
              <li><Link to="/uibuilder" onClick={toggleMenu}>Visual Editor</Link></li>
              <li><Link to="/CodeEditor" onClick={toggleMenu}>Code Editor</Link></li>
              <li><Link to="/media" onClick={toggleMenu}>Media</Link></li>
              <li><Link to="#" onClick={toggleMenu}>Analytics & Testing</Link></li>
              <li><Link to="/dashboard">Settings</Link></li>
            </>
          )}
          <li>
            {isLoggedIn ? (
              <button onClick={() => { handleLogout(); toggleMenu(); }}>Logout</button>
            ) : (
              <button onClick={() => { showModal(); toggleMenu(); }}>Log in</button>
            )}
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
