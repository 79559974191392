// frontend/src/components/properties-sidebar/utils.js

export const getRelevantTokens = (globalTokens, propertyType) => {
  if (!globalTokens || Object.keys(globalTokens).length === 0) {
    return {};
  }

  // Map propertyType to relevant token prefixes
  const tokenPrefixes = {
    color: ['brandColors', 'baseColors', 'feedbackColors', 'backgroundColors'],
    backgroundColor: ['brandColors', 'baseColors', 'feedbackColors', 'backgroundColors'],
    width: ['sizeScale', 'width'],
    height: ['sizeScale', 'height'],
    fontFamily: ['typography.fontFamily'],
    fontSize: ['typography.fontSize'],
    fontWeight: ['typography.fontWeight'],
    lineHeight: ['typography.lineHeight'],
    marginTop: ['sizeScale', 'margin.top'],
    marginRight: ['sizeScale', 'margin.right'],
    marginBottom: ['sizeScale', 'margin.bottom'],
    marginLeft: ['sizeScale', 'margin.left'],
    paddingTop: ['sizeScale', 'padding.top'],
    paddingRight: ['sizeScale', 'padding.right'],
    paddingBottom: ['sizeScale', 'padding.bottom'],
    paddingLeft: ['sizeScale', 'padding.left'],
    justifyContent: ['flexbox.justifyContent'],
    alignItems: ['flexbox.alignItems'],
    flexWrap: ['flexbox.flexWrap'],
    flexDirection: ['flexbox.flexDirection'],
    // Add more propertyType mappings as needed
  };

  const prefixes = tokenPrefixes[propertyType] || [];
  const relevantTokens = {};

  prefixes.forEach(prefix => {
    Object.entries(globalTokens).forEach(([key, value]) => {
      if (key.startsWith(prefix)) {
        relevantTokens[key] = value;
      }
    });
  });

  return relevantTokens;
};
