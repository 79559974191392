// frontend/src/components/ui_components/DivContainer/DivContainer.jsx
import React, { useContext } from 'react';
import { NestedComponentsContext } from '../../../contexts/NestedComponentsContext';
import ComponentAddPanel from './ComponentAddPanel';

const DivContainer = ({ id, tokens, onClick, ...props }) => {
  const { nestedComponents } = useContext(NestedComponentsContext);
  const nestedComponentsForContainer = nestedComponents[id] || {};

  const defaultTokens = {
    width: '100%',
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const mergedTokens = {
    ...defaultTokens,
    ...tokens,
  };

  const handleComponentClick = () => {
    onClick();
  };

  const allowDrop = (event) => {
    event.preventDefault();
  };

  const renderNestedComponents = () => {
    return Object.keys(nestedComponentsForContainer).map((columnId) => {
      const columnData = nestedComponentsForContainer[columnId];
      if (!Array.isArray(columnData)) {
        console.error('Expected columnData to be an array, received:', columnData);
        return null;
      }
      return columnData.map((component) => {
        const Component = component.component;
        return (
          <div key={component.id}>
            <Component 
              {...component.props} 
              id={component.id} 
              tokens={component.tokens} 
            />
          </div>
        );
      });
    });
  };

  return (
    <div
      style={mergedTokens}
      className="div-container"
      onClick={handleComponentClick}
      onDrop={(event) => onComponentDrop(event, null, null, id)}
      onDragOver={allowDrop}
    >
      <ComponentAddPanel divContainerId={id} />
      {renderNestedComponents()}
    </div>
  );
};

export default DivContainer;
