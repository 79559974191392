// frontend/src/hooks/useComponentDrop.js
import { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { PageContentContext } from '../contexts/PageContentContext';
import { NestedComponentsContext } from '../contexts/NestedComponentsContext';
import { getComponentProps } from './getComponentProps';
import { components as componentImports } from '../components/ComponentImports';
import { loadComponentFromS3, registerDynamicComponent } from '../components/DynamicComponentLoader';
import axios from 'axios'; // Added for fetching components from S3

const useComponentDrop = (components, token, designTokens) => {
  const { rows, setRows } = useContext(PageContentContext);
  const { addNestedComponent, moveNestedComponent } = useContext(NestedComponentsContext);

  /**
   * Handles the component drop event.
   * @param {Event} event - The drop event.
   * @param {number|null} rowIndex - The row index where the component is dropped.
   * @param {number|null} colIndex - The column index where the component is dropped.
   * @param {string|null} containerId - The ID of the container if dropped inside one.
   */
  const handleComponentDrop = async (event, rowIndex = null, colIndex = null, containerId = null) => {
    console.log('handleComponentDrop triggered');

    // Prevent default behavior
    if (event && typeof event.preventDefault === 'function') {
      event.preventDefault();
    } else {
      console.error('Invalid event object:', event);
      return;
    }

    // Retrieve the drag data from 'text/plain'
    const data = event.dataTransfer.getData('text/plain');
    if (!data) {
      console.error('No data found in drop event.');
      return;
    }

    let dragData;
    try {
      dragData = JSON.parse(data);
    } catch (error) {
      console.error('Error parsing drag data:', error);
      return;
    }

    const { componentSource, componentName, designTokens: componentDesignTokens } = dragData;
    console.log(`Dropped component name: "${componentName}", Source: "${componentSource}"`);

    if (!componentName) {
      console.error('Component name is missing in the drop event.');
      return;
    }

    // Handle S3 components if the source is 's3' and the component is not already loaded
    if (componentSource === 's3' && !components[componentName]?.component) {
      console.log(`Importing component "${componentName}" from S3...`);
      const ComponentFromS3 = await loadComponentFromS3(componentName, token);
      if (ComponentFromS3) {
        console.log(`Successfully loaded component "${componentName}" from S3.`);
        registerDynamicComponent(components, componentName, ComponentFromS3, designTokens); // Register the component
        console.log(`Registered component "${componentName}" dynamically.`);
      } else {
        console.error(`Failed to import component "${componentName}" from S3.`);
        return;
      }
    }

    // Retrieve the component module
    const ComponentModule = components[componentName]?.component;
    if (!ComponentModule) {
      console.error(`Component module not found for: "${componentName}"`);
      return;
    }

    // Extract the React component
    const Component = ComponentModule;
    console.log(`Loaded component "${componentName}" from ${componentSource === 's3' ? 'S3' : 'local sources'}.`);

    // Prepare the new component object
    const newComponent = {
      id: uuidv4(),
      component: Component,
      name: componentName,
      tokens: componentDesignTokens || {},
      props: getComponentProps(Component),
    };
    console.log('New component to add:', newComponent);

    if (containerId) {
      // Dropping inside a container
      console.log(`Dropping inside Container ID: "${containerId}", Column Index: ${colIndex}`);
      addNestedComponent(containerId, uuidv4(), colIndex, newComponent);
    } else if (rowIndex !== null && colIndex !== null) {
      const sourceRow = dragData.sourceRow;
      const sourceCol = dragData.sourceCol;

      if (sourceRow && sourceCol) {
        // Existing component is being moved
        const srcRowIndex = parseInt(sourceRow, 10);
        const srcColIndex = parseInt(sourceCol, 10);
        console.log(`Moving existing component from row ${srcRowIndex}, col ${srcColIndex}`);
        const componentToMove = rows[srcRowIndex].components[srcColIndex].splice(0, 1)[0];
        console.log('Component to move:', componentToMove);

        // Check if the target column exists and is an array
        if (!Array.isArray(rows[rowIndex].components[colIndex])) {
          rows[rowIndex].components[colIndex] = [];
        }

        rows[rowIndex].components[colIndex].push(componentToMove);
        console.log('Updated rows after moving component:', rows);
      } else {
        // New component is being added
        console.log(`Adding new component: "${componentName}"`);

        if (!Array.isArray(rows[rowIndex].components[colIndex])) {
          rows[rowIndex].components[colIndex] = [];
        }
        rows[rowIndex].components[colIndex].push(newComponent);
        console.log('Updated rows after adding new component:', rows);
      }
      setRows([...rows]);
    }

    // Save the updated page content to context
    savePageContentToContext();
  };

  /**
   * Saves the current page content structure to the context.
   */
  const savePageContentToContext = () => {
    const rowsArray = document.querySelectorAll('.page-container .row');
    const pageContentStructure = Array.from(rowsArray).map(row => {
      const columnsArray = row.querySelectorAll('.column');
      return {
        columns: Array.from(columnsArray).map(column => {
          const componentsArray = column.querySelectorAll('.rendered-component');
          return {
            columnSize: parseInt(column.getAttribute('data-column-size'), 10),
            components: Array.from(componentsArray).map(component => {
              const componentName = component.getAttribute('data-component-name');
              const componentId = component.getAttribute('id');
              const componentProps = getComponentProps(components[componentName]?.component) || {};  
              return {
                id: componentId,
                name: componentName,
                props: componentProps,
                tokens: components[componentName]?.tokens || {},
                nestedComponents: components[componentName]?.nestedComponents || [],
                // Include other necessary properties like 'tokens' if required
              };
            }),
          };
        }),
      };
    });
    //console.log('PageContainer - Saving page content:', pageContentStructure);
    setPageContent(pageContentStructure); // Update the React context
  };

  return handleComponentDrop;
};

export default useComponentDrop;
