// frontend/src/hooks/useDesignTokenManagement.js
import { useContext, useEffect } from 'react';
import axios from 'axios';
import { TokensContext } from '../contexts/TokensContext';

export const useDesignTokenManagement = (token) => {
  const {
    designTokens,
    setDesignTokens,
    editedTokens,
    setEditedTokens,
    globalTokens,
    setGlobalTokens,
    updateDesignTokens,
  } = useContext(TokensContext);

  const fetchTokens = async () => {
    try {
      console.log('useDesignTokenManagement: Fetching tokens...');
      const designTokenResponse = await axios.get('https://www.webbify.io/designTokens', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('useDesignTokenManagement: fetched designTokens:', designTokenResponse.data);
      setDesignTokens(designTokenResponse.data);

      const globalTokenResponse = await axios.get('https://www.webbify.io/globalTokens', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('useDesignTokenManagement: fetched globalTokens:', globalTokenResponse.data);
      setGlobalTokens(globalTokenResponse.data);

      // Remove 'global' key from editedTokens
      const newEditedTokens = {
        ...designTokenResponse.data,
      };
      console.log('useDesignTokenManagement: setting editedTokens to:', newEditedTokens);
      setEditedTokens(newEditedTokens);
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  const handleTokenChange = (newTokens) => {
    console.log('useDesignTokenManagement: handleTokenChange called with:', newTokens);
    setEditedTokens(newTokens);
  };

  useEffect(() => {
    if (token) {
      console.log('useDesignTokenManagement: token available, fetching tokens');
      fetchTokens();
    } else {
      console.log('useDesignTokenManagement: no token available');
    }
  }, [token]);

  return {
    designTokens,
    editedTokens,
    globalTokens,
    fetchTokens,
    handleTokenChange,
    updateDesignTokens,
    setEditedTokens,
  };
};
