// frontend/src/components/properties-sidebar/DimensionsSpacingOptions.js

import React from 'react';
import { Row, Col, Input, Dropdown, Button, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  SideTop,
  SideRight,
  SideBottom,
  SideLeft,
  SizeHeight,
  SizeWidth,
  PaddingTop,
  PaddingRight,
  PaddingBottom,
  PaddingLeft,
} from '../../assets/icons/properties-sidebar/icons';
import { getRelevantTokens } from './utils';

const DimensionsSpacingOptions = ({
  selectedComponentInfo,
  globalTokens,
  editedTokens,
  tokenNames,
  handleTokenChange,
  setEditedTokens,
  setTokenNames,
}) => {
  if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

  const labelInputDropdown = (label, icon, propertyType) => {
    const tokenName = tokenNames[selectedComponentInfo.id]?.[propertyType] || '';
    const inputValue = editedTokens[selectedComponentInfo.id]?.[propertyType] || '';

    const relevantTokens = getRelevantTokens(globalTokens, propertyType);

    const menu = (
      <Menu
        onClick={(e) => {
          handleTokenChange(selectedComponentInfo.id, propertyType, relevantTokens[e.key]);
          setEditedTokens((prevTokens) => ({
            ...prevTokens,
            [selectedComponentInfo.id]: {
              ...prevTokens[selectedComponentInfo.id],
              [propertyType]: relevantTokens[e.key],
            },
          }));
          // Update tokenNames to store the selected token name
          setTokenNames((prevTokenNames) => ({
            ...prevTokenNames,
            [selectedComponentInfo.id]: {
              ...prevTokenNames[selectedComponentInfo.id],
              [propertyType]: e.key,
            },
          }));
        }}
      >
        {Object.entries(relevantTokens).map(([key, value]) => (
          <Menu.Item key={key}>
            {key} - {value}
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <div
        className="prop-sidebar-item"
        style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
      >
        <label alt={label}>{icon}</label>
        <Input
          type="text"
          value={tokenName || inputValue}
          placeholder={propertyType}
          onChange={(e) => handleTokenChange(selectedComponentInfo.id, propertyType, e.target.value)}
        />
        {Object.keys(relevantTokens).length > 0 && (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button style={{ cursor: 'pointer' }}>
              <DownOutlined />
            </Button>
          </Dropdown>
        )}
      </div>
    );
  };

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('width', <SizeWidth />, 'width')}</Col>
        <Col span={12}>{labelInputDropdown('height', <SizeHeight />, 'height')}</Col>
      </Row>
      <label>Margins</label>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('margin-top', <SideTop />, 'marginTop')}</Col>
        <Col span={12}>{labelInputDropdown('margin-right', <SideRight />, 'marginRight')}</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('margin-bottom', <SideBottom />, 'marginBottom')}</Col>
        <Col span={12}>{labelInputDropdown('margin-left', <SideLeft />, 'marginLeft')}</Col>
      </Row>
      <label>Padding</label>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('padding-top', <PaddingTop />, 'paddingTop')}</Col>
        <Col span={12}>{labelInputDropdown('padding-right', <PaddingRight />, 'paddingRight')}</Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col span={12}>{labelInputDropdown('padding-bottom', <PaddingBottom />, 'paddingBottom')}</Col>
        <Col span={12}>{labelInputDropdown('padding-left', <PaddingLeft />, 'paddingLeft')}</Col>
      </Row>
    </>
  );
};

export default DimensionsSpacingOptions;
