// frontend/src/contexts/NestedComponentsContext.js
import React, { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const NestedComponentsContext = createContext();

export const NestedComponentsProvider = ({ children }) => {
    const [nestedComponents, setNestedComponents] = useState({});

    const addNestedComponent = (containerId, columnId, component) => {
        const newComponentWithId = { 
            ...component,
            id: component.id || uuidv4(),
            props: component.props || {}, 
            tokens: component.tokens || {}
        };
        setNestedComponents(prevState => {
            const updatedState = {
                ...prevState,
                [containerId]: {
                    ...(prevState[containerId] || {}),
                    [columnId]: [
                        ...(Array.isArray(prevState[containerId]?.[columnId]) ? prevState[containerId][columnId] : []),
                        newComponentWithId,
                    ],
                }
            };
            return updatedState;
        });
    };

    return (
        <NestedComponentsContext.Provider
            value={{
                nestedComponents,
                addNestedComponent
            }}
        >
            {children}
        </NestedComponentsContext.Provider>
    );
};