// frontend/src/contexts/ComponentPropsContext.js
import React, { createContext, useState } from 'react';

export const ComponentPropsContext = createContext();

export const ComponentPropsProvider = ({ children }) => {
  const [selectedComponentProps, setSelectedComponentProps] = useState({});

  const updateComponentProps = (componentId, newProps) => {
      console.log(`Updating props for component ${componentId}:`, newProps);
      setSelectedComponentProps(prevProps => ({
          ...prevProps,
          [componentId]: {
              ...prevProps[componentId],
              ...newProps
          }
      }));
  };

  return (
    <ComponentPropsContext.Provider
      value={{
        selectedComponentProps,
        updateComponentProps,
      }}
    >
      {children}
    </ComponentPropsContext.Provider>
  );
};