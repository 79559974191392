// frontend/src/hooks/usePageManagement.js 
import { useState, useEffect, useContext } from 'react';
import { PageContentContext } from '../contexts/PageContentContext';
import { generateUniquePageId, verifyUniquePageIds } from '../utils/pageUtils';
import { TokensContext } from '../contexts/TokensContext';
import useSavePageContent from './useSavePageContent';

const usePageManagement = (
  components, 
  globalTokens,
  designTokens, 
  editedTokens, 
  selectedComponentInfo, 
  setSelectedComponentInfo, 
  token,
  fetchedPages = []
) => {
    const [pages, setPages] = useState([]);
    const [activePage, setActivePage] = useState(null);
    const { setPageContent, setRows } = useContext(PageContentContext);
    const { setEditedTokens } = useContext(TokensContext);
    const savePageContent = useSavePageContent(token, pages, setPages, activePage, components, designTokens, selectedComponentInfo, setSelectedComponentInfo, editedTokens, setEditedTokens);

    // Fetching pages only on the first load and on manual save
    useEffect(() => {
        if (fetchedPages.length > 0) {
            setPages(fetchedPages);
            if (!activePage) {
                setActivePage(fetchedPages[0]?.pageId); // Automatically set active page if none is active
            }
        }
    }, [fetchedPages]);

    // Handle switching between pages and updating rows only when a page is manually selected
    useEffect(() => {
        if (activePage) {
            const activePageContent = pages.find(page => page.pageId === activePage);
            if (activePageContent && activePageContent.content && activePageContent.content.props) {
                setRows(activePageContent.content.props.rows); // Update rows on page switch
                console.log('Switching to page:', activePage, 'with rows:', activePageContent.content.props.rows);
            }
        }
    }, [activePage, pages, setRows]);

    // Verify page IDs and prevent fetching again on every interaction
    useEffect(() => {
        verifyUniquePageIds(pages);
    }, [pages]);

    return { pages, setPages, activePage, setActivePage };
};

export default usePageManagement;
