// frontend/src/components/ui_components/DivContainer/ComponentAddPanel.jsx
import React, { useState, useContext } from 'react';
import { NestedComponentsContext } from '../../../contexts/NestedComponentsContext';
import { v4 as uuidv4 } from 'uuid';
import { components as componentImports } from '../../ComponentImports';

const ComponentAddPanel = ({ divContainerId }) => {
    const [selectedComponent, setSelectedComponent] = useState('');
    const { addNestedComponent } = useContext(NestedComponentsContext);

    const handleAddComponent = async () => {
        console.log(`Adding component to DivContainer ID: ${divContainerId} with Component: ${selectedComponent}`);
        if (!selectedComponent) {
            alert('Please select a component to add.');
            return;
        }

        const ComponentModule = await componentImports[selectedComponent]();
        const Component = ComponentModule.default;

        const newComponent = {
            id: uuidv4(),
            component: Component,
            name: selectedComponent,
            props: {}, // Default props for the component
        };

        addNestedComponent(divContainerId, 0, newComponent); // Assuming a single column
    };

    return (
        <div className="component-add-panel">
            <button onClick={handleAddComponent}>Add Component</button>
            <select value={selectedComponent} onChange={(e) => setSelectedComponent(e.target.value)}>
                <option value="">Select Component</option>
                {Object.keys(componentImports).map((componentName) => (
                    <option key={componentName} value={componentName}>{componentName}</option>
                ))}
            </select>
        </div>
    );
};

export default ComponentAddPanel;