// frontend/src/components/MonacoEditor.js

import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Editor, { useMonaco } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';

const MonacoEditor = forwardRef(({ value, onChange, language }, ref) => {
  const editorRef = useRef(null);
  const [isSplitView, setIsSplitView] = useState(false);

  useImperativeHandle(ref, () => ({
    handleSearch: () => {
      // Inform the user to use keyboard shortcuts
      alert('Use Ctrl+F to search within the editor.');
    },
    handleReplace: () => {
      // Inform the user to use keyboard shortcuts or implement a custom replace feature
      alert('Use Ctrl+H to replace text within the editor.');
    },
    handleCopy: () => {
      navigator.clipboard.writeText(value);
      alert('Code copied to clipboard.');
    },
    handleUndo: () => {
      if (editorRef.current) {
        editorRef.current.trigger('keyboard', 'undo', null);
      }
    },
    handleRedo: () => {
      if (editorRef.current) {
        editorRef.current.trigger('keyboard', 'redo', null);
      }
    },
    toggleSplitView: () => {
      setIsSplitView(!isSplitView);
    },
  }));

  const handleEditorDidMount = (editor) => {
    editorRef.current = editor;
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: isSplitView ? 'row' : 'column' }}>
        <Editor
          height="100%"
          defaultLanguage={language}
          value={value}
          onChange={onChange}
          onMount={handleEditorDidMount}
          options={{
            selectOnLineNumbers: true,
            minimap: { enabled: false },
            automaticLayout: true,
            wordWrap: 'on',
            // Add any other options you need
          }}
        />
        {isSplitView && (
          <Editor
            height="100%"
            defaultLanguage={language}
            value={value}
            onChange={() => {}}
            onMount={() => {}}
            options={{
              readOnly: true,
              selectOnLineNumbers: true,
              minimap: { enabled: false },
              automaticLayout: true,
              wordWrap: 'on',
              // Add any other options you need
            }}
          />
        )}
      </div>
    </div>
  );
});

export default MonacoEditor;
