// frontend/src/components/homepage/HomeSection2/HomeSection2.js
import React, { useEffect, useRef } from 'react';
import './HomeSection2.css';
import HeadingKeyline from '../../../assets/homepage/headings/HeadingKeyline.svg';
import GitHubIcon from '../../../assets/homepage/HomeSection2/github-icon.svg'; 
import S3Icon from '../../../assets/homepage/HomeSection2/s3-icon.svg';
import SyncedIcon from '../../../assets/homepage/HomeSection2/synced-icon.svg';
import WebbifyIcon from '../../../assets/homepage/HomeSection2/webbify-icon.svg';

const HomeSection2 = () => {
  const headingRef = useRef(null);
  const iconRefs = useRef([]);
  const textRefs = useRef([]);

  useEffect(() => {
    const options = {
      threshold: 0.1, // Trigger when 10% of the element is visible
    };
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === headingRef.current) {
            entry.target.classList.add('slide-in-up');
          }
          iconRefs.current.forEach((icon, index) => {
            if (entry.target === icon) {
              setTimeout(() => {
                icon.classList.add('fade-in');
                textRefs.current[index].classList.add('fade-in');
              }, 500 * index); // Delay each icon by 0.5 seconds
            }
          });
        }
      });
    };
    const observer = new IntersectionObserver(handleIntersection, options);
    if (headingRef.current) observer.observe(headingRef.current);
    iconRefs.current.forEach((icon) => {
      if (icon) observer.observe(icon);
    });
    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      iconRefs.current.forEach((icon) => {
        if (icon) observer.unobserve(icon);
      });
    };
  }, []);

  return (
    <div className="home-section-2">
      <div ref={headingRef} className="homeHeadingH2">
        <img src={HeadingKeyline} alt="HeadingKeyline" className="HeadingKeyline" />
        <h2>Connect to GitHub</h2>
        <p>Work directly with your existing files in our app.</p>
      </div>
      <div className="steps">
        <div className="icon-row">
          <img ref={(el) => (iconRefs.current[0] = el)} src={WebbifyIcon} alt="Webbify" className="icon" />
          <p ref={(el) => (textRefs.current[0] = el)}>Log into and navigate to settings</p>
        </div>
        <div className="icon-row">
          <img ref={(el) => (iconRefs.current[1] = el)} src={GitHubIcon} alt="GitHub" className="icon" />
          <p ref={(el) => (textRefs.current[1] = el)}>Connect with GitHub & Clone repo</p>
        </div>
        <div className="icon-row">
          <img ref={(el) => (iconRefs.current[2] = el)} src={S3Icon} alt="S3 Bucket" className="icon" />
          <p ref={(el) => (textRefs.current[2] = el)}>Save repository to AWS S3 Bucket</p>
        </div>
        <div className="icon-row">
          <img ref={(el) => (iconRefs.current[3] = el)} src={SyncedIcon} alt="Synced Files" className="icon" />
          <p ref={(el) => (textRefs.current[3] = el)}>Sync S3 Bucket with GitHub repo</p>
        </div>
      </div>
    </div>
  );
};

export default HomeSection2;
