// frontend/src/components/GlobalTokenEditor.js
import React, { useContext, useState, useEffect } from 'react';
import { Modal } from 'antd';
import axios from 'axios';
import { TokensContext } from '../contexts/TokensContext';
import { isColor, convertColorToHex } from './colorUtils';

const GlobalTokenEditor = ({ isVisible, onClose }) => {
  const { globalTokens, setGlobalTokens } = useContext(TokensContext);
  const [localTokens, setLocalTokens] = useState({});
  const [originalTokens, setOriginalTokens] = useState({});

  const actualTokens = localTokens || {};

  const categorizeTokens = (tokens) => {
    if (!tokens) return {};
    return Object.keys(tokens).reduce((categories, key) => {
      const category = key.split('.')[0];
      if (!categories[category]) {
        categories[category] = {};
      }
      categories[category][key] = tokens[key];
      return categories;
    }, {});
  };

  const categorizedTokens = categorizeTokens(actualTokens);

  const handleTokenChange = (category, key, newValue) => {
    const adjustedValue = isColor(newValue)
      ? convertColorToHex(newValue)
      : newValue;
    const updatedTokens = {
      ...localTokens,
      [key]: adjustedValue,
    };
    setLocalTokens(updatedTokens);
  };

  const handleCancel = () => {
    console.log('GlobalTokenEditor: handleCancel called');
    if (originalTokens) {
      setLocalTokens(originalTokens);
    }
    if (onClose && typeof onClose === 'function') {
      console.log('GlobalTokenEditor: calling onClose');
      onClose();
    } else {
      console.error('GlobalTokenEditor: onClose is not defined or not a function');
    }
  };

  const handleSave = async () => {
    console.log('GlobalTokenEditor: handleSave called');
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.log('No token available.');
        return;
      }

      const response = await axios.put(
        'https://www.webbify.io/globalTokens',
        localTokens,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setGlobalTokens(localTokens);
      console.log('Tokens updated:', response.data);
    } catch (error) {
      console.error('Error updating tokens:', error.response ? error.response.data : error.message);
    }

    if (onClose && typeof onClose === 'function') {
      console.log('GlobalTokenEditor: calling onClose');
      onClose();
    } else {
      console.error('GlobalTokenEditor: onClose is not defined or not a function');
    }
  };

  useEffect(() => {
    console.log('GlobalTokenEditor: isVisible changed:', isVisible);
    console.log('GlobalTokenEditor: globalTokens updated:', globalTokens);
    if (isVisible && globalTokens) {
      setLocalTokens(globalTokens);
      setOriginalTokens(globalTokens);
    }
  }, [globalTokens, isVisible]);

  return (
    <Modal
      title="Edit Global Design Tokens"
      visible={isVisible}
      onCancel={handleCancel}
      onOk={handleSave}
      okText="Save"
    >
      {Object.entries(categorizedTokens).map(([category, tokens]) => (
        <div key={category}>
          <h3>{category}</h3>
          {Object.entries(tokens).map(([key, value]) => (
            <div key={key}>
              <label>{key}:</label>
              {isColor(value) ? (
                <>
                  <input
                    type="color"
                    value={actualTokens[key]}
                    onChange={(e) => handleTokenChange(category, key, e.target.value)}
                  />
                  <input
                    type="text"
                    value={actualTokens[key]}
                    onChange={(e) => handleTokenChange(category, key, e.target.value)}
                  />
                </>
              ) : (
                <input
                  type="text"
                  value={actualTokens[key]}
                  onChange={(e) => handleTokenChange(category, key, e.target.value)}
                />
              )}
            </div>
          ))}
        </div>
      ))}
    </Modal>
  );
};

export default GlobalTokenEditor;
