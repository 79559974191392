// frontend/src/components/properties-sidebar/ColorOptionsHelper.js

import React from 'react';
import { Dropdown, Button, Menu, Input } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { getRelevantTokens } from './utils';

export const renderColorPicker = (
  propertyType,
  selectedComponentInfo,
  globalTokens,
  editedTokens,
  handleTokenChange,
  tokenNames,
  setEditedTokens,
  setTokenNames
) => {
  if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

  const tokenName = tokenNames[selectedComponentInfo.id]?.[propertyType] || '';
  const inputValue = editedTokens[selectedComponentInfo.id]?.[propertyType] || '';

  const relevantTokens = getRelevantTokens(globalTokens, propertyType);

  const menu = (
    <Menu
      onClick={(e) => {
        handleTokenChange(selectedComponentInfo.id, propertyType, relevantTokens[e.key]);
        setEditedTokens((prevTokens) => ({
          ...prevTokens,
          [selectedComponentInfo.id]: {
            ...prevTokens[selectedComponentInfo.id],
            [propertyType]: relevantTokens[e.key],
          },
        }));
        // Update tokenNames to store the selected token name
        setTokenNames((prevTokenNames) => ({
          ...prevTokenNames,
          [selectedComponentInfo.id]: {
            ...prevTokenNames[selectedComponentInfo.id],
            [propertyType]: e.key,
          },
        }));
      }}
    >
      {Object.entries(relevantTokens).map(([key, value]) => (
        <Menu.Item key={key}>
          {key} - {value}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div
      className="prop-sidebar-item"
      style={{ position: 'relative', display: 'flex', alignItems: 'center' }}
    >
      <input
        className="color-picker"
        type="color"
        value={inputValue}
        onChange={(e) => handleTokenChange(selectedComponentInfo.id, propertyType, e.target.value)}
      />
      <Input
        type="text"
        value={tokenName || inputValue}
        placeholder={propertyType}
        onChange={(e) => handleTokenChange(selectedComponentInfo.id, propertyType, e.target.value)}
      />
      {Object.keys(relevantTokens).length > 0 && (
        <Dropdown overlay={menu} trigger={['click']}>
          <Button style={{ cursor: 'pointer' }}>
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};
