// frontend/src/components/PropertiesSidebar.js
import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { TokensContext } from '../contexts/TokensContext';
import { isColor } from './colorUtils';
import { Dropdown, Menu, Collapse, Input, Row, Col } from 'antd';
import { Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { SideTop, SideRight, SideBottom, SideLeft, TypeLineHeight, TypeFontSize, TypeFontFamily, TypeFontWeight, SizeHeight, SizeWidth, PaddingTop, PaddingRight, PaddingBottom, PaddingLeft } from '../assets/icons/properties-sidebar/icons'; 
import { JustifyContentFlexStart, JustifyContentFlexEnd, AlignItemsFlexStart, AlignItemsFlexEnd, JustifyContentCenter, AlignItemsCenter, MoreFlexOptions, AlignItemsSpaceBetween, JustifyContentSpaceBetween, AlignItemsSpaceAround, JustifyContentSpaceAround, AlignItemsSpaceEvenly, JustifyContentSpaceEvenly, FlexWrapNowrap, FlexWrapWrap, FlexDirectionRowReverse, FlexDirectionRow, FlexDirectionColumn, FlexDirectionColumnReverse } from '../assets/icons/properties-sidebar/icons'; 

import { ReactComponent as ArrowHeadUp } from '../assets/icons/properties-sidebar/arrow-head-up.svg'
import { ReactComponent as ArrowHeadDown } from '../assets/icons/properties-sidebar/arrow-head-down.svg'

import CustomExpandIcon from './properties-sidebar/CustomExpandIcon';
import FlexOptions from './properties-sidebar/FlexOptions';
import { handleFlexOptionChange, renderFlexOptionsDropdown } from './properties-sidebar/FlexOptionsHelper';
import DimensionsSpacingOptions from './properties-sidebar/DimensionsAndSpacing';
import { handleDimensionChange } from './properties-sidebar/DimensionsAndSpacingHelper';
import ColorOptions from './properties-sidebar/ColorOptions';
import { renderColorPicker } from './properties-sidebar/ColorOptionsHelper';
import { getRelevantTokens } from './properties-sidebar/utils';
import StaticContentPanel from './properties-sidebar/StaticContentPanel';

const PropertiesSidebar = ({
    fetchTokens,
    token,
    selectedComponentInfo,
    //onTokenChange
}) => {
    const { editedTokens, setEditedTokens, updateDesignTokens, globalTokens } = useContext(TokensContext);

    useEffect(() => {
      console.log('PropertiesSidebar: globalTokens updated:', globalTokens);
    }, [globalTokens]);

    const [colorValue, setColorValue] = useState(null);
    const [tokenNames, setTokenNames] = useState({});
    const { Panel } = Collapse;

    useEffect(() => {
      if (selectedComponentInfo) {
        // Validate color tokens
        const colorToken = selectedComponentInfo.tokens.color;
        if (colorToken && !/^#([0-9A-F]{3}){1,2}$/i.test(colorToken)) {
          console.warn(`Invalid color token format: "${colorToken}". Expected format is "#rrggbb".`);
          // Optionally, set a default color or notify the user
          updateDesignTokens(selectedComponentInfo.id, { color: '#000000' });
        }
      }
    }, [selectedComponentInfo, updateDesignTokens]);

    useEffect(() => {
        if (selectedComponentInfo && selectedComponentInfo.id) {
            // Get initial values for the selected component
            const componentId = selectedComponentInfo.id;
            const componentTokens = editedTokens[componentId] || {};

            // Loop through each property to populate the inputs
            const newTokenNames = {};
            Object.keys(componentTokens).forEach(propertyType => {
                const value = componentTokens[propertyType];

                // Check if the value corresponds to a global token
                const globalToken = Object.entries(globalTokens).find(([, tokenValue]) => tokenValue === value);
                if (globalToken) {
                    // Store the token name if the value is a global token
                    newTokenNames[propertyType] = globalToken[0];
                }
            });

            setTokenNames(newTokenNames);
        }
    }, [selectedComponentInfo, globalTokens, editedTokens]);

    const handleTokenChange = (componentId, propertyType, newValue) => {
      if (!componentId) {
        console.error('selectedComponentInfo is null, cannot change token');
        return;
      }
      const newTokens = {
        ...editedTokens,
        [componentId]: {
          ...editedTokens[componentId],
          [propertyType]: newValue,
        },
      };
      console.log('PropertiesSidebar: handleTokenChange called, newTokens:', newTokens);
      setEditedTokens(newTokens);
      setTokenNames((prevTokenNames) => ({
        ...prevTokenNames,
        [componentId]: {
          ...prevTokenNames[componentId],
          [propertyType]: '', // Clear the token name if manual value is entered
        },
      }));
    };

    const getTypographyOptions = (propertyType) => {
      if (!globalTokens || Object.keys(globalTokens).length === 0) {
        return [];
      }

      const typographyTokens = Object.entries(globalTokens).filter(([key]) =>
        key.startsWith(`typography.${propertyType}`)
      );

      return typographyTokens.map(([key, value]) => ({
        name: key,
        value: value,
      }));
    };

    const renderTypographyDropdown = (propertyType) => {
      if (!selectedComponentInfo || !selectedComponentInfo.id) return null;

      const tokenName = tokenNames[selectedComponentInfo.id]?.[propertyType] || '';
      const inputValue = editedTokens[selectedComponentInfo.id]?.[propertyType] || '';

      const options = getTypographyOptions(propertyType);

      return (
        <div className="prop-sidebar-item" style={{ display: 'flex', alignItems: 'center' }}>
          <Input
            placeholder={propertyType}
            value={inputValue || tokenName}
            onChange={(e) => handleTokenChange(selectedComponentInfo.id, propertyType, e.target.value)}
          />
          {options.length > 0 && (
            <Dropdown
              overlay={
                <Menu>
                  {options.map(({ name, value }) => (
                    <Menu.Item
                      key={name}
                      onClick={() => handleTokenChange(selectedComponentInfo.id, propertyType, value)}
                    >
                      {name} - {value}
                    </Menu.Item>
                  ))}
                </Menu>
              }
              trigger={['click']}
            >
              <Button style={{ cursor: 'pointer' }}>
                <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </div>
      );
    };

    const saveChanges = async (tokens) => {
      if (!selectedComponentInfo || !selectedComponentInfo.id) return;

      //const componentName = selectedComponentInfo.name;
      const componentId = selectedComponentInfo.id;

      try {
        // Ensure you have the correct designTokens from the context or props
        const designTokens = editedTokens[selectedComponentInfo.id] || {};
        
        const token = localStorage.getItem('token');
        const response = await axios.put(
          'https://www.webbify.io/designTokens',
          {
            componentId: componentId,
            designTokens: {
              ...designTokens,
              ...(tokens[selectedComponentInfo.id] || {}),
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update designTokens per component name
        updateDesignTokens(componentId, tokens[selectedComponentInfo.id] || {});

        // Update editedTokens per component ID
        setEditedTokens((prevTokens) => ({
          ...prevTokens,
          [selectedComponentInfo.id]: {
            ...prevTokens[selectedComponentInfo.id],
            ...tokens[selectedComponentInfo.id],
          },
        }));

        fetchTokens();
        console.log('Tokens updated:', response.data);
      } catch (error) {
        console.error(
          'Error updating tokens:',
          error.response ? error.response.data : error.message
        );
      }
    };

    return (
        <div className="properties-sidebar">
            <Collapse defaultActiveKey={['flex-options', 'dimensions-spacing', 'color-options', 'typography', '4']} ghost expandIcon={CustomExpandIcon} >
                {selectedComponentInfo && (
                    <>
                        <Panel header="Flex Options" key="flex-options">
                            <FlexOptions 
                                selectedComponentInfo={selectedComponentInfo}
                                handleTokenChange={handleTokenChange}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                setEditedTokens={setEditedTokens}
                                tokenNames={tokenNames}
                                setTokenNames={setTokenNames}
                            />
                        </Panel>
                        {/* Dimensions and spacing Options section */}
                        <Panel header="Dimensions and Spacing" key="dimensions-spacing">
                            <DimensionsSpacingOptions 
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                tokenNames={tokenNames}
                                setTokenNames={setTokenNames}
                                handleTokenChange={handleTokenChange}
                                setEditedTokens={setEditedTokens}
                            />
                        </Panel>

                        {/* Color Options section */}
                        <Panel className="colorPanel" header="Color Options" key="color-options">
                            <ColorOptions 
                                selectedComponentInfo={selectedComponentInfo}
                                globalTokens={globalTokens}
                                editedTokens={editedTokens}
                                tokenNames={tokenNames}
                                setTokenNames={setTokenNames}
                                handleTokenChange={handleTokenChange}
                                renderColorPicker={renderColorPicker}
                                setEditedTokens={setEditedTokens}
                            />
                        </Panel>

                        {/* Typography Options section */}
                        <Panel header="Typography Options" key="typography">
                          <Row gutter={[8, 8]}>
                            <Col span={24}>
                              <label alt="font-family">
                                <TypeFontFamily />
                              </label>
                              {renderTypographyDropdown('fontFamily')}
                            </Col>
                          </Row>
                          <Row gutter={[8, 8]}>
                            <Col span={24}>
                              <label alt="font-weight">
                                <TypeFontWeight />
                              </label>
                              {renderTypographyDropdown('fontWeight')}
                            </Col>
                          </Row>
                          <Row gutter={[8, 8]}>
                            <Col span={12}>
                              <label alt="font-size">
                                <TypeFontSize />
                              </label>
                              {renderTypographyDropdown('fontSize')}
                            </Col>
                            <Col span={12}>
                              <label alt="line-height">
                                <TypeLineHeight />
                              </label>
                              {renderTypographyDropdown('lineHeight')}
                            </Col>
                          </Row>
                        </Panel>
                    </>
                )}
                {!selectedComponentInfo && (
                    <StaticContentPanel 
                        handleTokenChange={handleTokenChange}
                        editedTokens={editedTokens}
                        setEditedTokens={setEditedTokens}
                        selectedComponentInfo={selectedComponentInfo}
                        globalTokens={globalTokens}
                        handleDimensionChange={handleTokenChange}
                        tokenNames={tokenNames}
                        renderColorPicker={renderColorPicker}
                        renderTypographyDropdown={renderTypographyDropdown}
                    />
                )}
            </Collapse>
            <div className="properties-sidebar-bottom-btn">
                <button onClick={() => { console.log('Save button clicked'); saveChanges(editedTokens); }}>Save</button>
            </div>
        </div>
    );
};

export default PropertiesSidebar;
