// frontend/src/components/ComponentImports.js
export const components = {
  TextInput: () => import('./ui_components/TextInput/TextInput'),
  PrimaryButton: () => import('./ui_components/Button/PrimaryButton'),
  SecondaryButton: () => import('./ui_components/Button/SecondaryButton'),
  TertiaryButton: () => import('./ui_components/Button/TertiaryButton'),
  IconButton: () => import('./ui_components/Button/IconButton'),
  DivContainer: () => import('./ui_components/DivContainer/DivContainer'),
  Text: () => import('./ui_components/Text/Text'),
  Image: () => import('./ui_components/Image/Image'),
  Container: () => import('./ui_components/Container/Container'),
  // Add other components as needed
};
