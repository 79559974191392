// frontend/src/components/SelectionPanel.js
import React, { useContext, useState, useEffect } from 'react';
import { ComponentPropsContext } from '../contexts/ComponentPropsContext';
import Draggable from 'react-draggable'; 
import { Switch, Collapse } from 'antd';

const SelectionPanel = ({ selectedComponentInfo, onClose }) => {
  const { updateComponentProps } = useContext(ComponentPropsContext);
  const [activeTab, setActiveTab] = useState('Visual Editor');
  const [localProps, setLocalProps] = useState(selectedComponentInfo?.props || {});
  const [colorValue, setColorValue] = useState(null);
  const [tokenNames, setTokenNames] = useState({});
  const { Panel } = Collapse;

  useEffect(() => {
      setLocalProps(selectedComponentInfo?.props || {});
  }, [selectedComponentInfo]);

  const handlePropChange = (propName, newValue) => {
    console.log(`Updating prop ${propName} to ${newValue}`); // New log
    updateComponentProps(selectedComponentInfo.id, { [propName]: newValue });
  };

  const handleInputBlur = (propName, value) => {
      handlePropChange(propName, value);
  };

  return (
    <Draggable bounds="parent">
      <div className="selection-panel">
        <header>
          <h4>Selected component: {selectedComponentInfo?.name}</h4>
          <button onClick={onClose}>Close</button>
        </header>
        <div className="tabs">
          <button onClick={() => setActiveTab('Visual Editor')} active={activeTab === 'Visual Editor'}>Visual Editor</button>
          <button onClick={() => setActiveTab('Empty Tab')} active={activeTab === 'Empty Tab'}>Empty Tab Button</button>
          {/* ...other tabs */}
        </div>
        {activeTab === 'Visual Editor' && (
          <div className="visual-editor">
            {/* Render inputs for each prop */}
            {Object.entries(localProps).map(([propName, propValue]) => (
              <div key={propName}>
                <label>{propName}</label>
                {typeof propValue === 'boolean' ? (
                  <Switch
                    checked={propValue}
                    onChange={(checked) => handlePropChange(propName, checked)}
                  />
                ) : typeof propValue === 'string' && propName === 'variant' ? (
                  <select
                    value={propValue}
                    onChange={(e) => handlePropChange(propName, e.target.value)}
                  >
                    {Object.keys(selectedComponentInfo.props?.variants || {}).map((variantKey) => (
                      <option key={variantKey} value={variantKey}>
                        {variantKey.charAt(0).toUpperCase() + variantKey.slice(1)}
                      </option>
                    ))}
                  </select>
                ) : propName === 'content' ? (
                  <input
                    value={propValue}
                    onChange={(e) => setLocalProps((prev) => ({ ...prev, [propName]: e.target.value }))}
                    onBlur={() => handleInputBlur(propName, propValue)}
                  />
                ) : (
                  <input
                    value={propValue}
                    onChange={(e) => setLocalProps((prev) => ({ ...prev, [propName]: e.target.value }))}
                    onBlur={() => handleInputBlur(propName, propValue)}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {activeTab === 'Empty Tab' && (
          <div className="empty-tab">
            {/* Empty tab content goes here */}
          </div>
        )}
      </div>
    </Draggable>
  );
};

export default SelectionPanel;
