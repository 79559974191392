// frontend/src/components/colorUtils.js
export const isColor = (value) => {
  if (typeof value !== 'string') return false;
  const colorRegex = /^#([0-9A-F]{3}){1,2}$/i;
  const namedColors = ["red", "green", "blue", "cyan", "magenta", "yellow", "black", "white", /*...other named colors*/];
  return colorRegex.test(value) || namedColors.includes(value.toLowerCase());
};

const colorNameToHex = {
  red: "#FF0000",
  green: "#008000",
  blue: "#0000FF",
  // ... add all other color name to hex mappings
};

export const convertColorToHex = (color) => {
  if (colorNameToHex[color.toLowerCase()]) {
    return colorNameToHex[color.toLowerCase()];
  }
  return color;  // return original value if not a recognized color name
};